var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"courses-filters my-2"},[_c('div',[_c('h5',[_vm._v("Level")]),_c('b-form-select',{on:{"change":_vm.onSelect},model:{value:(_vm.selectedLevel),callback:function ($$v) {_vm.selectedLevel=$$v},expression:"selectedLevel"}},[_c('b-form-select-option',{key:"no_level",attrs:{"value":null}},[_vm._v(" All Levels ")]),_vm._l((_vm.$store.getters[
            'home/getCourseLevels'
          ]),function(course_level,index){return _c('b-form-select-option',{key:index + '_level',attrs:{"value":course_level.course_level_id}},[_vm._v(" "+_vm._s(course_level.course_level_name)+" ")])})],2)],1),_c('div',[_c('h5',[_vm._v("Category:")]),_c('b-form-select',{on:{"change":_vm.onSelect},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},[_c('b-form-select-option',{key:"no_category",attrs:{"value":null}},[_vm._v(" All Categories ")]),_vm._l((_vm.$store.getters[
            'home/getCourseCategories'
          ]),function(course_category,index){return _c('b-form-select-option',{key:index + '_category',attrs:{"value":course_category.course_category_id}},[_vm._v(" "+_vm._s(course_category.course_category_name)+" ")])})],2)],1)]),_c('div',{staticClass:"course-collapse"},[_c('app-collapse',_vm._l((_vm.courseList),function(course,index){return _c('div',{key:index + '_course'},[_c('app-collapse-item',{ref:'course_' + course.course_id,refInFor:true,attrs:{"title":"Title"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Course',{attrs:{"id":'course_' + course.course_id,"course":course,"courseCollapse":true},on:{"reload-list":function (e) {
                  _vm.$emit('reload-details');
                },"apply-course":function (e) {
                  _vm.$emit('apply-course', e);
                },"apply-course-for-student":function (e) {
                  _vm.$emit('apply-course-for-student', e);
                },"recommend-course":function (e) {
                  _vm.$emit('recommend-course', e);
                }}})]},proxy:true}],null,true)},[_c('Course',{attrs:{"course":course,"courseDetail":true},on:{"apply-course-for-student":function (e) {
                _vm.$emit('apply-course-for-student', e);
              },"recommend-course":function (e) {
                _vm.$emit('recommend-course', e);
              }}})],1)],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }